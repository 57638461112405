.modal-button {
    font-family: 'Caveat', cursive;
    text-align: center;
}

.modal-button:hover {
    cursor: pointer;
}

.modal-button.my-projects:hover {
    color: gray;
}

.modal-button.view-more:hover {
    color: gray;
}

.modal-button.my-projects-icon {
    font-size: 110px;
    color: aliceblue;
    width: 110px;
}

.modal-button.my-projects-icon:hover {
    text-align: center;
    font-size: 110px;
}

.modal-button.view-more-icon {
    font-size: 120px;
    color: rgb(161, 109, 109);
}

.modal-button.view-more-icon:hover{
    font-size: 120px;
    color: #bec8db
}

.modal-button.about-me-projects {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.modal-button.about-me-projects:hover {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    text-decoration: underline;
    background-color: transparent;
}

.modal-button.about-me-projects.light-hover:hover {
    color: white;
}

.modal-button.about-me-projects.dark-hover:hover {
    color: gray;
}




@media screen and (max-width: 900px) {
    .modal-button {
        font-size: 18px;
    }
    
    .modal-button:hover {
        font-size: 18px;
    }

    .modal-button.my-projects-icon {
        font-size: 50px;
        color: aliceblue;
    }
    
    .modal-button.my-projects-icon:hover {
        font-size: 50px;
        color: aliceblue;
    }
    
    .modal-button.view-more-icon {
        font-size: 50px;
        color: #B4C5E4
    }
    
    .modal-button.view-more-icon:hover{
        font-size: 50px;
        color: #B4C5E4
    }


}

@media screen and (min-width: 901px) {
    .modal-button {
        font-size: 28px;
    }

    .modal-button:hover {
        font-size: 30px;
    }
    
    
    
}