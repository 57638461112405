.big-main-background {
    border: 1px solid transparent;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    bottom: 40%;
    right: 6%;
}

.big-main-background.light {
    background-color: rgb(241, 238, 238);
    box-shadow: 3px 3px 5px gray;
    
}

.big-main-background.dark {
    box-shadow: 3px 3px 5px rgb(96, 95, 95);
    background-color: rgb(126, 126, 126);
}

.big-polaroid-image {
    background-image: url('../../assets/headshot.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 25%;
    margin: 5%;
    background-color: #545252;
    height: 80%;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgb(185, 181, 181);
}

.big-polaroid-caption {
    margin: 0 5% 5% 5%;
    border-radius: 3px;
    box-shadow: -1px -1px 1px rgb(185, 181, 181);
}

.big-polaroid-caption.light {
    background-color: whitesmoke;
    
}

.big-polaroid-caption.dark {
    background-color: rgb(216, 213, 213);
}

.big-caption {
    font-family: 'Caveat', cursive;
    font-size: 30%;
    width: 100%;
}

@media screen and (max-width: 900px) {
    .big-main-background {
        width: 200px;
        height: 300px;
        margin-top: 70px;
    }

    .big-polaroid-caption {
        font-size: 70%;
        padding: 10px;

    }

    .big-caption {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .polaroid-big-separator {
        font-size: 50%;
    }
    
}

@media screen and (min-width: 901px) {
    .big-main-background {
        width: 50%;
        height: 140%;
    }

    .big-polaroid-caption {
        height: 30%;
        display: flex;
    }

    .big-caption {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .polaroid-big-separator {
        font-size: 50%;
    }

}