.main-background {
    border: 1px solid transparent;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    box-sizing: border-box;
}

.main-background.light {
    box-shadow: 3px 3px 5px gray;
    background-color: rgb(241, 238, 238);
}

.main-background.dark {
    box-shadow: 3px 3px 5px rgb(96, 95, 95);
    background-color: rgb(126, 126, 126);

}

.main-background.view-more {
    bottom: -12%;
    transform: rotate(-4deg);
}

.main-background.my-projects {
    bottom: -12%;
    transform: rotate(4deg);
    left: 34%;
}

.main-background.email-me {
    bottom: -12%;
    transform: rotate(-6deg);
    left: 70%;   
}

.main-background.resume {
    transform: rotate(3deg);
}

.polaroid-image {
    margin: 7%;
    height: 80%;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgb(185, 181, 181);
    box-sizing: border-box;
}

.polaroid-image.light {
    background-color: rgb(187, 181, 181);
    
}

.polaroid-image.dark {
    background-color: rgb(148, 145, 145);
}

.polaroid-image.my-projects {
    filter: greyscale(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}


.polaroid-image.view-more {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: sepia(50%);
}

.polaroid-image.email-me {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    filter: sepia(50%) ;
}

.polaroid-image.email-me a {
    color: maroon;
}

.polaroid-image.email-me:hover {
    cursor: pointer;
    background-color: maroon;
}

.polaroid-image.email-me a:hover {
    color: gray;
}

.polaroid-caption {
    height: 20%;
    margin: 10px;
    border-radius: 3px;
    box-shadow: -1px -1px 1px rgb(185, 181, 181);
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.polaroid-caption.light {
    background-color: whitesmoke;
    
}

.polaroid-caption.dark {
    background-color: rgb(216, 213, 213);
}

.resume-caption {
    font-family: 'Caveat', cursive;
    color: black;
}

.circle {
    z-index: 1;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 150px;
    box-shadow: 1px 1px 1px;
}

.circle.resume {
    background-color: #FFBA49;
    right: 38%;
    bottom: 101%;
}

.circle.view-more {
    background-color: #69a197;
    /* left: 100px; */
    left: 52%;
    /* top: -22px; */
    top: -9.5%;
}

.circle.my-projects {
    background-color: #aa78a6;
    /* left: 92.5px; */
    left: 48.2%;
    /* top: -27.9px; */
    top: -12.3%;
}

.circle.email-me {
    background-color: #f44708;
    /* left: 70px; */
    left: 33%;
    top: -15%;
}

.line {
    position: absolute;
    width: 12px;
    border: 1px solid black;
    /* left: 90px; */
    left: 47.5%;
    /* top: 4px; */
    top: 1.62%;
}

.line.view-more{
    transform: rotate(-15deg);
}

.line.my-projects {
    transform: rotate(-50deg);
}

.line.email-me {
    top: -1%;
    transform: rotate(-130deg);
}

.line.resume {
    top: -1%;
    left: 58%;
    transform: rotate(30deg);
}

.polaroid-caption.email-me a {
    color: black;
    font-family: 'Caveat', cursive;
    font-size: 28px;
}

.polaroid-caption.email-me a:hover {
    color: gray;
}

.resume-caption:hover {
    color: gray;
}

.polaroid-image.resume a{
    color: black
}

.polaroid-image.resume {
    box-sizing: border-box;
}

#resume-hover {
    width: 110px;
    /* font-size: 105px; */
}

.polaroid-caption.resume {
    font-size: 20px;
    font-family: 'Caveat', cursive;
}


@media screen and (max-width: 986px) {
    .main-background {
        width: 150px;
        height: 230px;
    } 
    .polaroid-caption.resume {
        font-size: 20px;
        font-family: 'Caveat', cursive;
    }

    .polaroid-image.resume {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 120px;
    }

    .resume-caption {
        font-size: 20px;
        font-family: 'Caveat', cursive;
    }

    #resume-hover {
        margin-left: 55px;
    }
}


@media screen and (max-width: 900px) {
    .main-background {
        width: 100px;
        margin-top: 100px;
        height: 150px;
        box-sizing: border-box;
    }

    .polaroid-image {
        height: 90px;
    }
    .polaroid-image.email-me {
        font-size: 50px;
    }

    .polaroid-caption.email-me a{
        font-size: 20px;
    }

    .polaroid-image.resume {
        font-size: 50px;
    }

    .polaroid-image.polaroid-image.my-projects {
        font-size: 50px;
    }

    .polaroid-caption.resume {
        font-size: 20px;
        font-family: 'Caveat', cursive;
    }
    .resume-caption {
        /* font-size: 28px; */
        font-family: 'Caveat', cursive;
    }

}

@media screen and (min-width: 986px) {
    .main-background {
        margin: 4%;
        width: 18%;
        height: 230px;
        box-sizing: border-box;
    }

    .resume-caption {
        font-size: 28px;
        font-family: 'Caveat', cursive;
    }

    .resume-caption:hover {
        font-size: 30px;
    }

    .polaroid-caption {
        font-family: 'Caveat', cursive;
        text-align: center;
        font-size: 30px;
    }

    .polaroid-caption:hover{
        font-family: 'Caveat', cursive;
        text-align: center;
        font-size: 32px;
        color: gray;
        cursor: pointer;
    }

    .polaroid-caption.email-me a:hover {
        font-size: 30px;
    }

    .polaroid-caption.resume {
        font-size: 20px;
        font-family: 'Caveat', cursive;
    }

    .polaroid-image.resume {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 120px;
        text-align: center;
    }

    .polaroid-image.my-projects {
        font-size: 115px;
    }

}
