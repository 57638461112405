
    .projects-sticky {
        position: relative;
        animation: .5s ease-in 0s 1 slideInFromLeft;
    }
    
    .projects-sticky-tape {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.834);
        width: 250px;
        height: 60px;
        top: -3px;
        right: -50px;
        transform: rotate(30deg);
    }


    .project {
        display: flex;
        margin: 5px 15px 20px;
        align-items: center;
        justify-content: space-evenly;
        padding-bottom: 30px;
        border-bottom: 1px solid gray;
    }

    .project-first-div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
    }

    @media screen and (max-width: 900px) {
        .project {
            flex-direction: column;
        }
        
    }

    .technologies {
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        border: 1px solid black;
        border-radius: 3px;
        width: 300px;
        font-size: 15px;
        align-items: center;
        justify-content: space-evenly;
    }

    .separator {
        font-size: 10px;
    }

    .project-caption {
        width: 200px;
        font-size: 25px;
    }
    
    .project-image {
        /* border: 1px solid black; */
        border-radius: 3px;
        background-repeat: no-repeat;
    }
    
    .project-image.sparkle {
        height: 300px;
        width: 300px;
        background-image: url("../../assets/sparkle.png");
        background-size: cover;
    }

    .project-image.bang {
        height: 300px;
        width: 300px;
        background-image: url("../../assets/bang.png");
        background-size: contain;
    }

    .project-image.cubberd {
        height: 200px;
        width: 300px;
        background-image: url("../../assets/cubberd.png");
        background-size: contain;
    }

    .project-links {
        font-size: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

  
    .sticky.top.projects {
        overflow: scroll;
        overflow-x: hidden;
    }
    