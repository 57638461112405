.pin-whole {
    position: relative;
}


.circle-pin {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 150px;
    bottom: 58%;
    box-shadow: 1px 1px 1px;
}

.circle-pin.sticky-note {
    left: 20%;
    top: -28px;
    background-color: #188FA7;    
}

.circle-pin.big {
    background-color: #EB4B98;
    left: 32%;
    top: -32px;
}

.line-pin {
    position: absolute;
    width: 12px;
    border: 1px solid black;
    left: 30%;
}
.line-pin.sticky-note {
    transform: rotate(38deg);
}

.line-pin.big {
    transform: rotate(130deg);
    bottom: 100%;
}

/* .line-pin.big{
    position: absolute;
    transform: rotate(38deg);
    bottom: 100%;
    width: 17px;
    border: 1px solid black;
    left: 30%;
} */