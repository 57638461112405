.about-me-sticky {
    animation: .5s ease-in 0s 1 slideInFromTop;
    width: 500px;
    height: 500px;
    background: transparent;
    position: relative;
}

.sticky-tape {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.834);
    width: 240px;
    height: 50px;
    top: -10px;
    left: -50px;
    transform: rotate(-30deg);
}

.exit-modal {
    position: absolute;
    right: 8px;
    top: 8px;
}

.exit-modal:hover {
    cursor: pointer;
    color: gray;
}

.about-me-blurb {
    margin: 6% 6% 0 6%;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about-me-links {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: transparent;
}

.about-me-links a{
    color: black;
    transition: .3s;
    font-size: 15px;
}

.about-me-links a:hover {
    text-decoration: underline;
    transition: .3s;
}

.about-me-links.light a:hover {
    color: white;
}

.about-me-links.dark a:hover {
    color: gray;
}

.contact-me {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   width: 600px;
   margin-left: 50px;
   font-family: Arial, Helvetica, sans-serif;
}

.skills-list {
    font-size: 15px;
}