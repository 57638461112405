.dark-mode {
    margin: 10px 0 0 10px;
}

.dark-mode-button {
    border: 1px solid transparent;
    border-radius: 80px;
    width: 70px;
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
}

.dark-mode-button:hover {
    cursor: pointer;
}

@keyframes slide-in-from-left {
    0% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-in-from-right {
    0% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(0);
    }
}


.dark-mode-button.light {
    background-image: linear-gradient(#051937, #162648, #26335a, #36416d, #475080);
}

.dark-mode-button.dark {
    background-image: linear-gradient(to top, #baf2f4, #b4eef1, #bbe8ec, #bfe9ee, #c6eff4);
}

.fa-moon {
    transform: rotate(225deg);
}

#moon-icon {
    color: whitesmoke;
    position: absolute;
    left: 52px;
    top: 7px;
    animation: .05s ease-in 0s 1 slide-in-from-left;
}

#sun-icon {
    color: rgb(214, 214, 16);
    position: absolute;
    bottom: 7px;
    left: 7px;
    animation: .05s ease-in 0s 1 slide-in-from-right;
    font-size: 18px;
}

