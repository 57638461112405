@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.sticky {
    box-shadow: 5px 5px 4px gray;
    border-radius: 2px;
}

.sticky.bottom {
    display: flex;
    border: 0 solid transparent;
}

.sticky.bottom.right.hov.intro {
    border-bottom-right-radius: 100px;
    background-color: rgb(209, 209, 37);
}

.sticky-caption {
    padding-top: 30px;
    margin: 10px auto auto auto;
    text-align: center;
    font-family: 'Caveat', cursive;
}

.sticky-caption.intro {
    animation: 1s ease-in 0s 1 slideInFromTop;
}

@media screen and (max-width: 400px) {
    
}

@media screen and (max-width: 900px) {
    .sticky {
        width: 200px;
    }

    .sticky.about-me {
        width: 500px;
    }

    
    .sticky.top {
        margin-top: 30px;
        height: 200px;
    }

    .sticky.top.intro {
        background-color: yellow;
    }

    .sticky.top.about-me {
        background-color: rgb(229, 137, 194);
        overflow: scroll;
        overflow-x: hidden;
    }

    .sticky.bottom.about-me {
        height: 80px;
    }
    
    .sticky.bottom.left.about-me {
        background-color: rgb(229, 137, 194);
        width: 450px;
    }

    .sticky.sticky.bottom.right.about-me {
        border-bottom-right-radius: 100px;
        background-color: rgb(225, 81, 170);
        width: 50px;


    }

    .sticky.top.projects {
        background-color: greenyellow;
    }

    .sticky.top.view-more {
        background-color: aquamarine;
        height: 290px;
    }

    .sticky.bottom.left.view-more {
        background-color: aquamarine;
        width: 180px;
    }

    .sticky.bottom.right.view-more {
        border-bottom-right-radius: 100px;
        background-color: rgb(37, 236, 170);
        width: 30px;
    }
    
    .sticky.bottom {
        height: 30px;
        margin-bottom: 20px;
    }

    .sticky.bottom.left {
        background-color: yellow;
        width: 180px;
    }
    
    .sticky.bottom.right {
        background-color: yellow;
        width: 20px;
    }

    .sticky.bottom.right.projects {
        border-bottom-right-radius: 100px;
        background-color: rgb(138, 225, 7);
        width: 80px;
    }

    .sticky.bottom.left.projects {
        background-color: greenyellow;
        width: 730px;
    }

    .sticky.projects {
        width: 400px;
    }
}

@media screen and (min-width: 901px) {
    .sticky.intro {
        width: 268px;
    }

    .sticky-caption.intro {
        font-size: 70px;
    }

    .sticky.about-me {
        width: 500px;
    }

    .sticky.view-more {
        width: 300px;
    }

    .sticky.projects {
        width: 800px;
    }
    
    .sticky.top.intro {
        background-color: yellow;
        height: 231px;
    }

    .sticky.top.about-me {
        background-color:rgb(229, 137, 194);
        height: 430px;
    }

    .sticky.top.view-more {
        background-color: aquamarine;
        height: 300px;
    }

    .sticky.top.projects {
        background-color: greenyellow;
        height: 450px;
    }

    .sticky.bottom.view-more {
        height: 30px;
    }
    
    .sticky.bottom.intro {
        height: 40px;
    }

    .sticky.bottom.about-me {
        height: 70px;
    }

    .sticky.bottom.projects {
        height: 50px;
    }

    .sticky.bottom.left.intro {
        background-color: yellow;
        width: 232px;
        border: 0 solid transparent;
    }

    .sticky.bottom.left.about-me {
        background-color: rgb(229, 137, 194);
        width: 450px;
    }

    .sticky.bottom.left.view-more {
        background-color: aquamarine;
        width: 270px;
    }

    .sticky.bottom.left.projects {
        background-color: greenyellow;
        width: 760px;
    }
    
    .sticky.bottom.right {
        border: 0 solid transparent;
    }

    .sticky.bottom.right.intro {
        background-color: yellow;
        width: 38px;
    }

    .sticky.bottom.right.about-me {
        border-bottom-right-radius: 100px;
        background-color: rgb(225, 81, 170);;
        width: 50px;
    }

    .sticky.bottom.right.view-more {
        border-bottom-right-radius: 100px;
        background-color: rgb(37, 236, 170);
        width: 30px;
    }

    .sticky.bottom.right.projects {
        border-bottom-right-radius: 100px;
        background-color: rgb(138, 225, 7);
        width: 40px;
    }

    .hi-im-adina {
        font-size: 80px;
    }


   
}
