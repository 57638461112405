.cork-board-main {
    background-image: url("../../assets/hd-wallpaper-g99fc2da66_1280.jpeg");
    width: 55%;
    border-radius: 10px;
    box-shadow: -2px 2px 9px 2px black;
    font-size: 70px;
    display: flex;
    flex-direction: column;
    margin: 1% auto;
}

.cork-board-main-bottom {
    display: flex;
}

@media screen and (max-width: 900px) {
    .cork-board-main {
        height: 1800px;
        align-items: center;
    }
    .cork-board-main-bottom {
        justify-content: space-between;
    }
}

@media screen and (max-width: 600px) {
    .cork-board-main-bottom {
        flex-direction: column;
        height: 50px;
    }
    
}

@media screen and (min-width: 901px ) {
    .main-page {
        border-image: round;
        background-repeat: no-repeat;
        display: flex;
        justify-self: center;
    }
    
    .cork-board-main {
        height: 600px;
        font-size: 70px;
        display: flex;
        flex-direction: column;
    }
    
    .cork-board-main-top {
        display: flex;
        justify-content: space-between;
        margin: 5% 5% 12% 5%;
    }
    
    .headshot {
        margin: 25% 35%;
        border-radius: 150px;
        width: 290px;
        height: 290px;
        z-index: 1;
    
    }
    
    .cork-board-main-bottom {
        justify-content: space-between;
        margin-top: 20px;
    }




    
}
