/* TODO Add site wide styles */
@import url('reset.css');

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }   
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
    
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(200%);
    }
    100% {
        transform: translateX(0);
    }
    
}


.light {
    background-color: rgba(187, 244, 205, 0.45);
}

.dark {
    background-color: #165d5b;
}


@media screen and (max-width: 900px) {
    body {
        height: 1000px;
    }
}

@media screen and (min-width: 901px) {
    body {
        /* background-color: rgba(187, 244, 205, 0.45); */
        min-height: 100vh;
    }
}
        
.noselect {
    -webkit-touch-callout: none;  
      -webkit-user-select: none; 
       -khtml-user-select: none;
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none;
  }