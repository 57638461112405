
    .view-more-container {
        position: relative;
        animation: .5s ease-in 0s 1 slideInFromRight;
    }

    .view-more-sticky-tape {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.834);
        width: 100px;
        height: 40px;
        top: -20px;
        right: 100px;
        transform: rotate(30deg);
    }

    .view-more-links {
        display: flex;
        flex-direction: column;
    }

    .view-more-link {
        margin: 15px;
        font-size: 60px;
        color: rgb(247, 165, 106);
        transition: .3s;
    }

    .view-more-link:hover {
        color: rgb(239, 204, 179);
        transition: .3s;
    }