@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }   
}

#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.modal-background.modal-projects {
    background-color: rgba(221, 243, 228, 0.766);
}

.modal-background.modal-about-me {
    background-color: rgba(221, 243, 228, 0.766);
}

.modal-background.modal-view-more {
    background-color: rgba(221, 243, 228, 0.766);
    /* background-color: rgba(221, 243, 228, 0.92); */
}

/* 
#modal-view-more {
    transition: 1s;
    font-family: 'Roboto', sans-serif;
    border-radius: .75rem;
    padding: 10px;
    position: absolute;
    background-color: rgb(246, 79, 149);
    height: 50%;
} */
